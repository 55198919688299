import React, { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img1 from '../assets/images/imgggg/background/sm4.webp';
import img2 from '../assets/images/imgggg/background/logo designing.webp';
import img3 from '../assets/images/imgggg/background/landscape brochure.webp';
import img4 from '../assets/images/imgggg/background/catalogue.webp';
import img5 from '../assets/images/imgggg/background/hoarding .webp';
import img6 from '../assets/images/imgggg/background/maazine.webp';
import img7 from '../assets/images/imgggg/background/press and ads.webp';
import img8 from '../assets/images/imgggg/background/web dev.webp';

import rightarrow from '../assets/images/icon/image.webp'

import sm1 from '../assets/images/imgggg/portfolio/social media/sm1.webp'
import sm2 from '../assets/images/imgggg/portfolio/social media/sm2.webp'
import sm3 from '../assets/images/imgggg/portfolio/social media/sm3.webp'
import sm4 from '../assets/images/imgggg/portfolio/social media/sm4.webp'
import sm5 from '../assets/images/imgggg/portfolio/social media/sm5.webp'
import sm6 from '../assets/images/imgggg/portfolio/social media/sm6.webp'
import sm7 from '../assets/images/imgggg/portfolio/social media/sm7.webp'
import sm8 from '../assets/images/imgggg/portfolio/social media/sm8.webp'
import sm9 from '../assets/images/imgggg/portfolio/social media/sm9.webp'
import sm10 from '../assets/images/imgggg/portfolio/social media/sm10.webp'
import sm11 from '../assets/images/imgggg/portfolio/social media/sm11.webp'
import sm12 from '../assets/images/imgggg/portfolio/social media/sm12.webp'
import sm14 from '../assets/images/imgggg/portfolio/social media/sm14.webp'


import brochure1 from '../assets/images/imgggg/portfolio/brochure/3.webp'
import brochure2 from '../assets/images/imgggg/portfolio/brochure/4.webp'
import brochure3 from '../assets/images/imgggg/portfolio/brochure/5.webp'
import brochure4 from '../assets/images/imgggg/portfolio/brochure/6.webp'
import brochure5 from '../assets/images/imgggg/portfolio/brochure/7.webp'
import brochure6 from '../assets/images/imgggg/portfolio/brochure/8.webp'
import brochure7 from '../assets/images/imgggg/portfolio/brochure/Picture.webp'
import brochure8 from '../assets/images/imgggg/portfolio/brochure/Picture2.webp'


import logo1 from '../assets/images/imgggg/portfolio/logo/10.webp'
import logo2 from '../assets/images/imgggg/portfolio/logo/9.webp'
import logo3 from '../assets/images/imgggg/portfolio/logo/8.webp'
import logo4 from '../assets/images/imgggg/portfolio/logo/7.webp'
import logo5 from '../assets/images/imgggg/portfolio/logo/6.webp'
import logo6 from '../assets/images/imgggg/portfolio/logo/5.webp'
import logo7 from '../assets/images/imgggg/portfolio/logo/4.webp'
// import logo8 from '../assets/images/imgggg/portfolio/logo/'
import logo9 from '../assets/images/imgggg/portfolio/logo/2.webp'
import logo10 from '../assets/images/imgggg/portfolio/logo/11.webp'
import logo11 from '../assets/images/imgggg/portfolio/logo/Picture.webp'

import magazine1 from '../assets/images/imgggg/portfolio/magazine/2.webp'
import magazine2 from '../assets/images/imgggg/portfolio/magazine/3.webp'
import magazine3 from '../assets/images/imgggg/portfolio/magazine/4.webp'
import magazine4 from '../assets/images/imgggg/portfolio/magazine/Picture.webp'

import ads1 from '../assets/images/imgggg/portfolio/press-and-ads/1.webp'
import ads2 from '../assets/images/imgggg/portfolio/press-and-ads/2.webp'
import ads3 from '../assets/images/imgggg/portfolio/press-and-ads/3.webp'
import ads4 from '../assets/images/imgggg/portfolio/press-and-ads/4.webp'

import web1 from '../assets/images/imgggg/portfolio/web/1.webp'
import web2 from '../assets/images/imgggg/portfolio/web/2.webp'
import web3 from '../assets/images/imgggg/portfolio/web/3.webp'
import web4 from '../assets/images/imgggg/portfolio/web/4.webp'
import web5 from '../assets/images/imgggg/portfolio/web/5.webp'

import catalogue from '../assets/images/imgggg/portfolio/catalogue/catalogue.png'

import hoarding1 from '../assets/images/imgggg/portfolio/hoarding/2.png'
import hoarding2 from '../assets/images/imgggg/portfolio/hoarding/3.png'
import hoarding3 from '../assets/images/imgggg/portfolio/hoarding/Picture.png'

import { useNavigate } from 'react-router-dom';



import aimahal from '../assets/images/imgggg/portfolio/logo/ai-webp/AiMahalBanner.webp'
import grock from '../assets/images/imgggg/portfolio/logo/grock-webp/2.webp'
import grades from '../assets/images/imgggg/portfolio/logo/grades-webp/first cover.webp'

import { useHistory, useParams } from "react-router-dom";

import Transition from '../components/transitions/transition';
import Scrolltrigger from 'scrolltrigger';

const images = [img1, img2, img3, img4, img5, img3, img4, img5];
const data = [
    {
        id: 1,
        sliderImg: img1,
        imgData: [sm1, sm9, sm7, sm2, sm3, sm4, sm5, sm6, sm8, sm14, sm10, sm11, sm12],
        heading: "Social Media",
    },
    {
        id: 2,
        sliderImg: img2,
        imgData: [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo9, logo10, logo11],
        heading: "Logo Branding",
    },
    {
        id: 3,
        sliderImg: img3,
        imgData: [brochure1, brochure2, brochure3, brochure4, brochure5, brochure6, brochure7, brochure8],
        heading: "Landscape Brochure",
    },
    {
        id: 4,
        sliderImg: img4,
        imgData: [catalogue],
        heading: "Catalogue",
    },
    {
        id: 5,
        sliderImg: img5,
        imgData: [hoarding1, hoarding2, hoarding3],
        heading: "Hoarding",
    }, {
        id: 6,
        sliderImg: img6,
        imgData: [magazine1, magazine2, magazine3, magazine4],
        heading: "Magazine and Notice",
    }, {
        id: 7,
        sliderImg: img7,
        imgData: [ads1, ads2, ads3, ads4],
        heading: "Press and Ads",
    }, {
        id: 8,
        sliderImg: img8,
        imgData: [web1, web2, web3, web4, web5],
        heading: "Web",
    },
];

const individualData = [
    {
        id: 1,
        header: 'AI MAHAL',
        images: aimahal,
        link: '/company-branding/ai-mahal'
    },
    {
        id: 2,
        header: 'Grades International',
        images: grades,
        link: '/company-branding/grades-international'
    },
    {
        id: 3,
        header: 'G Rock',
        images: grock,
        link: '/company-branding/G-rock'
    }
]


function NextPage() {
    useEffect(() => {
        const numberOfImages = data.length;
        let activeThumbnail = null;

        gsap.registerPlugin(ScrollTrigger);

        for (let i = 1; i <= numberOfImages; i++) {
            const randomLeft = getRandomLeft();

            ScrollTrigger.create({
                trigger: `.image-${i}`,
                start: "top center",
                end: "bottom center",
                onToggle: self => {
                    const thumbnailDiv = document.querySelector(`.thumbnail-${i}`);
                    if (self.isActive) {
                        if (activeThumbnail && activeThumbnail !== thumbnailDiv) {
                            animateThumbnail(activeThumbnail, false);
                        }
                        animateThumbnail(thumbnailDiv, true);
                        activeThumbnail = thumbnailDiv;
                    } else if (activeThumbnail === thumbnailDiv) {
                        animateThumbnail(thumbnailDiv, false);
                    }
                }
            });
        }


    }, []);

    const { paramsId } = useParams();
    let navigate = useNavigate();
    const paramValue = data.length;

    const slideNum = Number(paramsId) + 1;

    const [parvalue, setParvalue] = useState(0)
    const [navigationValue, setNavigationValue] = useState(1);
    const [navigateCondition, setNavigateCondition] = useState(false);

    useEffect(() => {
        if (paramValue == Number(paramsId)) {
            console.log("params value 0")
            setParvalue(0)
            setNavigationValue(1)
            setNavigateCondition(true)
        } else {
            console.log("params value :", paramsId)
            setParvalue(Number(paramsId))
            setNavigationValue(Number(paramsId) + 1)
        }
    }, [paramsId, paramValue])


    console.log(paramsId, "para")

    let parId = paramsId - 1

    console.log(parId, "par id")


    const galleryRef = useRef(null);

    function getRandomLeft() {
        const values = [-15, -7.5, 0, 7.5, 15];
        return values[Math.floor(Math.random() * values.length)] + 'px';
    }

    function animateThumbnail(thumbnail, isActive) {
        gsap.to(thumbnail, {
            border: isActive ? '1px solid #fff' : 'none',
            opacity: isActive ? 1 : 0.5,
            scale: isActive ? 1.3 : 1,
            zIndex: isActive ? 10000 : 1,
            duration: 0.3
        });
    }

    function handleThumbnailClick(index) {
        const imageElement = galleryRef.current.querySelector(`.image-${index}`);
        if (imageElement) {
            imageElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const toSlider = () => {

        console.log("click calls", navigationValue, "par value")
        const nextSliderLoad = document.querySelector('.nextpage');
        const nextSliderImg = document.querySelector('.innerimg');
        const subHeading = document.querySelector('.nextpage-subheading');
        const testpagediv = document.querySelector('.testpagediv');
        const gallery = document.querySelector('.gallery');

        nextSliderLoad.style.clipPath = 'inset(0 0 0 0)';
        nextSliderImg.style.display = 'block';

        setTimeout(() => {

            subHeading.style.clipPath = 'inset(100% 100% 100% 100%)';
            testpagediv.style.transform = 'translateY(-800px)';
            testpagediv.style.transition = 'transform 1.5s ease';
            testpagediv.addEventListener('transitionend', () => {
                navigate('/company-branding/works/' + navigationValue);
            });
        }, 1600);
    }

    const toSlider2 = () => {
        navigate('/company-branding/works/' + navigationValue);
    }

    const handlelogoClick =(value)=>{
        console.log(individualData[value].link,"dsasdasd")
        navigate(individualData[value].link);
    }
    // document.querySelectorAll('.nextpage-Wrap').forEach(function (trigegrElement) {
    //     let targetElement = trigegrElement.querySelector('.nextpage');
    //     let targetelemntimg = trigegrElement.querySelector('.nextpage-img')

    //     let tl = gsap.timeline({
    //         scrollTrigger: {
    //             trigger: trigegrElement,
    //             start: "top top",
    //             end: "bottom bottom",
    //             scrub: 1,// set to true for smooth scrubbing,
    //             ease: 'power1.out'
    //         },
    //         scrollTrigger: {
    //             trigger: targetelemntimg,
    //             start: "top top",
    //             end: "bottom bottom",
    //             scrub: 1,// set to true for smooth scrubbing,
    //             ease: 'power1.out'
    //         }
    //     });

    //     tl.fromTo(targetElement, {
    //         width: '35em',
    //         height: '35em',
    //         // borderRadius: '35em'
    //     }, {
    //         width: "100vw",
    //         height: '100vh',
    //         // borderRadius: '0em'
    //     });
    //     tl.fromTo(targetelemntimg, {
    //         width: '35em',
    //         height: '35em',
    //         // borderRadius: '35em'
    //     }, {
    //         width: "100vw",
    //         height: '100vh',
    //         // borderRadius: '0em'
    //     });
    // });



    // const nextPageScroll =()=>{
    //     gsap.registerPlugin(Scrolltrigger);
    //     const mainDiv = document.querySelector(".nextpage");
    //     const imgDiv = document.querySelector(".nextpage-Wrap");


    //     gsap.to(".nextpage",{
    //         scrollTrigger:{
    //             trigger:".nextpage",
    //             start:"top top",
    //             end:"bottom bottom",
    //             scrub:1,

    //         },

    //     })

    // }
    const sectionRef = useRef(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const value = window.scrollY;
    //         if (sectionRef.current) {
    //             sectionRef.current.style.clipPath = `circle(${value}px at center center)`;
    //         }
    //     };
    //     window.addEventListener('scroll', handleScroll);
    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, []);


    ////////////////////////////////////////clip path code for scroll and grow. but due to the scroll value issue the code didnt work as expected.

    // const [isInView, setIsInView] = useState(false);
    // const [initialScrollY, setInitialScrollY] = useState(0);

    // useEffect(() => {
    //     const handleIntersection = (entries) => {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 console.log('Nextpage is at the top of the viewport');
    //                 setIsInView(true);
    //                 setInitialScrollY(window.scrollY);
    //             } else {
    //                 setIsInView(false);
    //             }
    //         });
    //     };

    //     const observer = new IntersectionObserver(handleIntersection, {
    //         root: null,
    //         threshold: 0,
    //     });

    //     if (sectionRef.current) {
    //         observer.observe(sectionRef.current);
    //     }

    //     return () => {
    //         if (sectionRef.current) {
    //             observer.unobserve(sectionRef.current);
    //         }
    //     };
    // }, []);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (isInView) {
    //             const value1 = window.scrollY
    //             console.log(value1,"value1")
    //             if (sectionRef.current && value1>1030) {
    //                 const value = 350+(window.scrollY - initialScrollY);
    //                 console.log(value,"val")
    //                 sectionRef.current.style.clipPath = `circle(${value}px at center center)`;
    //             }
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [isInView, initialScrollY]);

    ////////////////////////
    // const [isAtTop, setIsAtTop] = useState(false);
    // const [initialScrollY, setInitialScrollY] = useState(0);

    // useEffect(() => {
    //   const handleIntersection = (entries, observer) => {
    //     entries.forEach(entry => {
    //       // Check if sectionRef is intersecting with viewport and its top is at or above viewport top
    //       if (entry.isIntersecting && entry.intersectionRatio > 0 && entry.boundingClientRect.top <= 0) {
    //         console.log('Nextpage is at the top of the viewport');
    //         setIsAtTop(true);
    //         setInitialScrollY(window.scrollY);
    //         observer.unobserve(sectionRef.current); // stop observing once condition is met
    //       }
    //     });
    //   };

    //   const observer = new IntersectionObserver(handleIntersection, {
    //     root: null,
    //     threshold: 0,
    //     rootMargin: '0px', // No root margin needed now
    //   });

    //   if (sectionRef.current) {
    //     observer.observe(sectionRef.current);
    //   }

    //   return () => {
    //     if (sectionRef.current) {
    //       observer.unobserve(sectionRef.current);
    //     }
    //   };
    // }, []);

    // useEffect(() => {
    //   const handleScroll = () => {
    //     if (isAtTop) {
    //       console.log(window.scrollY - initialScrollY);
    //     }
    //   };

    //   window.addEventListener('scroll', handleScroll);

    //   return () => {
    //     window.removeEventListener('scroll', handleScroll);
    //   };
    // }, [isAtTop, initialScrollY]);

    //////<--------------------->////////
    useEffect(() => {
        const header = document.querySelector("header");
        header.style.display = 'none'
    }, [])
    const brand = gsap.timeline()

    return (
        <div className='testpagediv' style={{ position: 'relative' }} key={paramsId} >
            {!navigateCondition && paramsId == 1 && <Transition timeline={brand} key={paramsId} />}
            <a href='/company-branding/works'><div style={{ position: 'absolute', left: 0, top: 0, marginLeft: '25px', marginTop: '25px', cursor: 'pointer', zIndex: '1' }}> <h6 >Back</h6></div></a>

            <div className="gallery" ref={galleryRef} >
                <div className="images">
                    {data[parId].id != 2 ?
                        (data[parId].imgData.map((img, i) => (
                            <div key={i} className={`img image-${i + 1}`} style={{ left: getRandomLeft() }}>
                                <img src={img} alt={`Image ${i + 1}`} className='gallery-img' />
                            </div>
                        ))) :
                        (individualData.map((data, i) => (
                            
                                <div  onClick={()=>handlelogoClick(i)} key={i} className={`img image-${i + 1}`} style={{ left: getRandomLeft() ,cursor:'pointer'}}>
                                    <img src={data.images} alt={`Image ${i + 1}`} className='gallery-img' />
                                </div>
                           

                        )))
                    }
                </div>
                <div className="minimap">
                    {data[parId].id != 2 ?

                        (data[parId].imgData.map((img, i) => (

                            <div key={i} className={`img-thumbnail thumbnail-${i + 1}`} style={{ left: getRandomLeft() }} onClick={() => handleThumbnailClick(i + 1)}>
                                <img src={img} alt={`Thumbnail ${i + 1}`} className='img-thumbnail-img' />
                            </div>
                        ))) : (
                            individualData.map((data, i) => (
                               
                                    <div key={i} className={`img-thumbnail thumbnail-${i + 1}`} style={{ left: getRandomLeft() }} onClick={() => handleThumbnailClick(i + 1)}>
                                        <img src={data.images} alt={`Thumbnail ${i + 1}`} className='img-thumbnail-img' />
                                    </div>
                                
                            ))
                        )

                    }
                </div>
            </div>
            <div className='nextpage-Wrap' >
                <div key={parvalue} onClick={() => toSlider()} className="nextpage" ref={sectionRef} >
                    <img src={data[parvalue].sliderImg} className='innerimg' />
                </div>
                <div onClick={() => toSlider()} className='nextpage-heading'>
                    <h2 style={{ display: 'flex', justifyContent: 'center' }}>{data[parvalue].heading}</h2>
                    <p key={parvalue} className='nextpage-subheading' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '21px', fontWeight: 500 }}>Click and Go to next slide</p>
                </div>
            </div>
            {/* <div onClick={() => toSlider2()} className='gotoslide' style={{display:'flex',justifyContent:'center',marginBottom:'25px'}}><h4> Go to next slide</h4><span style={{paddingLeft:'5px',justifyContent:'center',display:'flex',alignItems:'center'}}><img style={{height:'25px'}} src={rightarrow} /></span></div> */}
            {/* <Row style={{display:"flex",flexDirection:'row'}}>
                <Col xs={2} lg={2} md={2}>
                    <div style={{display:'flex'}}><img style={{height:'45px',clipPath:'circle()'}} src={data[parvalue].sliderImg} /><p style={{ display: 'flex', justifyContent: 'center' , fontWeight: 600, alignItems: 'center'}}>{data[parvalue].heading}</p></div>
                    
                    <p key={parvalue}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '10px', fontWeight: 500 }}>previous slide</p>
                </Col>
                <Col xs={2} lg={2} md={2}>
                <div style={{display:'flex'}}><p style={{ display: 'flex', justifyContent: 'center' , fontWeight: 600, alignItems: 'center'}}>{data[parvalue].heading}</p><img style={{height:'45px',clipPath:'circle()'}} src={data[parvalue].sliderImg} /></div>
                <p key={parvalue}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '10px', fontWeight: 500 }}>Next slide</p>
                </Col>
            </Row> */}

        </div>
    );
}

export default NextPage;
