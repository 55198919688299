
import img1 from '../images/imgggg/layouts/swdev 3.webp'
import img2 from '../images/imgggg/layouts/Designer (9).webp'
import img3 from '../images/imgggg/layouts/appdev 1.webp'
import img4 from '../images/imgggg/layouts/marketing 4.webp'
import img5 from '../images/imgggg/layouts/overworked-businessman-showing-financial-graphs-presentation-using-tablet-brainstorming-company-ideas.webp'
import img6 from '../images/imgggg/layouts/seo3.webp'




const dataItem = [

    {
        id: 1,
        img: img1,
        title: 'Software Development',
    },
    {
        id: 2,
        img: img2,
        title: 'Website Development',
    },
    {
        id: 3,
        img: img3,
        title: 'Application Development',
    },
    {
        id: 4,
        img: img4,
        title: 'Digital Marketing',
    },
    {
        id: 5,
        img: img5,
        title: 'Wholistic Company Branding',
    },
    {
        id: 6,
        img: img6,
        title: 'SEO Consulting Services',
    }
    

]

export default dataItem;